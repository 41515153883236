import ClientRequest from './client-request';
import ServerRequest from './server-request';

export default class WxAPI {
  private _request;

  constructor(request: ServerRequest | ClientRequest) {
    this._request = request;
  }

  async getOauthToken(code: string) {
    const res = await this._request.get(`/v1/wx/utils/oauth/token`, {
      params: { code },
    })
    return res.data
  }

  async getWxConfig(url:string) {
    const res = await this._request.post(`/v1/wx/utils/js/sdk/sign`, {data: {url}})
    return res.data
  }
}
