import ClientRequest from './client-request';
import ServerRequest from './server-request';

export default class PositionAPI {
  private _request;

  constructor(request: ServerRequest | ClientRequest) {
    this._request = request;
  }

  async getPositionShareConfig(channel: string) {
    const params = {
      key: `MKTH5.ChannelShareConfig.${channel}`,
      language: 'en',
      version: '1',
    };
    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { data: params },
    );
    return JSON.parse(data.data.json);
  }

  async getPositionByIds(positionIds: number[]) {
    const res = await this._request.post('/v1/event/data', {
      data: { type: 1, params: positionIds },
    });
    return res.data;
  }

  async getActivityPositionSearch(sourceTypeValue: string) {
    const res = await this._request.post('/v1/ops/activity/position/search', {
      data: {
        searchItems: [
          {
            key: 'sourceType',
            value: sourceTypeValue,
            action: 'eq',
          },
          {
            key: 'organizationId',
            value: '13434',
            action: 'eq',
          },
          {
            key: 'accountId',
            value: '7398',
            action: 'eq',
          },
        ],
      },
      params: {
        current: 1,
        size: 100,
      },
    });
    return res.data;
  }
}
