import ClientRequest from './client-request';
import ServerRequest from './server-request';

export default class EventsAPI {
  private _request;

  constructor(request: ServerRequest | ClientRequest) {
    this._request = request;
  }

  async getEventInfo(eventCode: string) {
    const res = await this._request.get(`/v1/event/info`, {
      params: { eventCode },
    });
    return res.data;
  }

  async getEventData(params: any[], type: number) {
    const res = await this._request.post(`/v1/event/data`, {
      data: { params, type },
    });
    return res.data;
  }

  async getSpecialEventConfig(eventKey: string) {
    const params = {
      key: `MKTH5.SpecialEvent.${eventKey}`,
      language: 'zh',
      version: '1',
    };

    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { data: params },
    );
    return JSON.parse(data.data.json);
  }

  async getHotEventConfig(eventId: string) {
    const params = {
      key: `backend.eventHotOrganization.${eventId}`,
      language: 'en',
      version: '1',
    };
    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { data: params },
    );
    return JSON.parse(data.data.json);
  }

  async getHotOrganization(eventId: string) {
    const res = await this._request.get(
      `/v1/event/${eventId}/hot/organization`,
    );
    return res.data;
  }

  async getHotOrganizationTotal(eventId: string) {
    const res = await this._request.get(
      `/v1/event/${eventId}/hot/organization/total`,
    );
    return res.data;
  }

  async getHeadOrganization(eventId: string) {
    const res = await this._request.get(
      `/v1/event/${eventId}/head/organization`,
    );
    return res.data;
  }

  async getHeadOrganizationTotal(eventId: string, params = {}) {
    const res = await this._request.get(
      `/v1/event/${eventId}/head/organization/total`,
      { params },
    );
    return res.data;
  }

  async setOrganizationPopular(eventId: string, params: any) {
    const res = await this._request.post(`/v1/event/${eventId}/operate`, {
      data: params,
    });
    return res.data;
  }

  async getOperateList(eventId: string, params: any) {
    const res = await this._request.post(`/v1/event/${eventId}/operate/list`, {
      data: params,
    });
    return res.data;
  }

  async setFeedback(eventId: string, params: any) {
    const res = await this._request.post(`/v1/event/feedback`, {
      data: {
        eventId,
        ...params,
      },
    });
    return res.data;
  }

  async getCorporateEventConfig(eventId: string) {
    const params = {
      key: `MKTH5.CorporateEvent.${eventId}`,
      language: 'en',
      version: '1',
    };
    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { data: params },
    );
    return JSON.parse(data.data.json);
  }

  async getGlobalJobsEventConfig(eventId: string, lang: string) {
    const params = {
      key: `MKTH5.global-jobs.${eventId}`,
      language: lang,
      version: '1',
    }
    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { data: params },
    );
    return JSON.parse(data.data.json);
  }

  async setApply(eventId: string, params: any) {
    const res = await this._request.post(`/v1/event/apply`, {
      data: {
        eventId,
        ...params,
      },
    });
    return res.data;
  }

  async getAggregationEventConfig(eventId: string) {
    const params = {
      key: `MKTH5.AggregationEvent.${eventId}`,
      language: 'en',
      version: '1',
    };
    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { data: params },
    );
    return JSON.parse(data.data.json);
  }

  async getEventEnterpriseConfig(eventId: string) {
    const params = {
      key: `event.enterprise.${eventId}`,
      language: 'en',
      version: '1',
    };
    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { data: params },
    );
    return JSON.parse(data.data.json);
  }

  async getIndustryPosition(eventId: string) {
    const res = await this._request.get(
      `/v1/event/${eventId}/industry/position`,
    );
    return res.data;
  }

  async getEventMapEnterpriseConfig(eventId: string) {
    const params = {
      key: `event.mapEnterprise.${eventId}`,
      language: 'en',
      version: '1',
    };
    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { data: params },
    );
    return JSON.parse(data.data.json);
  }
}
