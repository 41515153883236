const BUILD_ENV = process.env.NEXT_PUBLIC_BUILD_ENV;

const API_BASE_URL =
  process.env.NEXT_PUBLIC_BUILD_ENV === 'production'
    ? `https://www.smartdeer.com`
    : `https://global-test.smartdeer.work`;

function getServerAPIBaseURL(env: string | undefined) {
  switch (env) {
    case 'production':
      return 'https://www.smartdeer.com/api';
    case 'test':
      return 'https://global-test.smartdeer.work/api';
    default:
      return 'https://global-test.smartdeer.work/api';
    // return `https://www.smartdeer.com/api`;
  }
}

function getClientAPIBaseURL(env: string | undefined) {
  switch (env) {
    case 'production':
      return 'https://www.smartdeer.com/api';
    default:
      return 'https://global-test.smartdeer.work/api';
    // return `https://www.smartdeer.com/api`;
  }
}

export const SERVER_API_BASE_URL = getServerAPIBaseURL(BUILD_ENV);
export const CLIENT_API_BASE_URL = getClientAPIBaseURL(BUILD_ENV);
export default API_BASE_URL;
