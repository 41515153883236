import axios from 'axios';
import { CLIENT_API_BASE_URL } from './config';

export default class ClientRequest {
  private _request;
  private _acceptLanguage: LANG = 'en';

  constructor(params: CreateRequestParams) {
    this._acceptLanguage = params.acceptLanguage || 'en';

    const defaultHeaders = {
      // 'Accept-Language': this._acceptLanguage,
    };

    this._request = axios.create({
      baseURL: CLIENT_API_BASE_URL,
      headers: defaultHeaders,
      timeout: 1000 * 30,
    });

    // 添加请求拦截器
    this._request.interceptors.request.use((request: any) => {
      request.headers['Accept-Language'] = this._acceptLanguage;
      return request;
    });

    /**
     * 如果响应数据中状态码大于等于400, 则抛出错误，表示错误。
     * 即使http status code是200，也会被拦截
     */
    this._request.interceptors.response.use(
      (response) => {
        if (response.data && response.data.code >= 400) {
          throw new Error(response.data.message);
        } else {
          return response;
        }
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  }

  public get lang() {
    return this._acceptLanguage;
  }

  public set accessLanguage(lang: LANG) {
    this._acceptLanguage = lang;
  }

  public get accessLanguage() {
    return this._acceptLanguage;
  }

  public async get(url: string, config?: RequestConfig) {
    const res = await this._request({
      method: 'get',
      url,
      ...config,
    });
    return res;
  }

  public async post(url: string, config?: RequestConfig) {
    const res = await this._request({
      method: 'post',
      url,
      ...config,
    });
    return res;
  }

  public async put(url: string, config?: RequestConfig) {
    const res = await this._request({
      method: 'put',
      url,
      ...config,
    });
    return res;
  }

  public async delete(url: string, config?: RequestConfig) {
    const res = await this._request({
      method: 'delete',
      url,
      ...config,
    });
    return res;
  }

  public async patch(url: string, config?: RequestConfig) {
    const res = await this._request({
      method: 'patch',
      url,
      ...config,
    });
    return res;
  }
}
