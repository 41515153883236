import { getTraceLink } from '@/utils/hyperlink';
import accepts from 'accepts';

/**
 * @description 给数字或者金额千分位添加逗号
 * @param money {number} 必须传入数字类型
 * @param precision {number} 精度 默认是小数点后0位
 * @param splitDesc {string} 分隔符
 * */
export const formatMoney = (
  money: number,
  precision: number = 0,
  splitDesc: string = ',',
) => {
  const str = money.toFixed(precision);
  const reg =
    str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(reg, '$1' + splitDesc);
};

/**
 * @description 获取当前系统环境
 * */
export const getSystemEnv = () => {
  const navigator = global.navigator;

  if (!navigator?.userAgent) return;
  const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否为iOS终端
  const isAndroid =
    navigator.userAgent.indexOf('Android') > -1 ||
    navigator.userAgent.indexOf('Adr') > -1; // 判断是否为Android终端

  if (isIOS) {
    console.log('当前是iOS环境');
    return 'ios';
  } else if (isAndroid) {
    console.log('当前是Android环境');
    return 'android';
  }

  return 'other';
};

// 判断是不是微信环境
export const isWeChat = () => {
  const navigator = global.navigator;

  if (!navigator?.userAgent) return false;

  const ua: any = navigator?.userAgent.toLowerCase();

  return ua.match(/MicroMessenger/i) == 'micromessenger';
};

// 判断是不是Facebook
const isFacebook = () => {
  const navigator = global.navigator;

  if (!navigator?.userAgent) return true;

  const ua: any = navigator?.userAgent.toLowerCase();

  return ua.indexOf('fb_');
};

// 检查app是否打开
function checkOpen(cb: Function) {
  const clickTime = +new Date();
  function check(elsTime: number) {
    if (elsTime > 3000 || document.hidden) {
      cb(true);
    } else {
      cb(false);
    }
  }
  // 启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
  let count = 0;
  let intHandle: any = null;
  intHandle = setInterval(() => {
    count++;
    const elsTime = +new Date() - clickTime;
    if (count >= 100 || elsTime > 3000) {
      clearInterval(intHandle);
      check(elsTime);
    }
  }, 20);
}
/**
 * 唤起app
 * @param {any} uri  app schema
 * @param {any} fallback  唤起失败回调
 */
function checkApp(uri: string, fallback: Function) {
  const ifr = document.createElement('iframe');
  ifr.src = uri;
  ifr.style.display = 'none';
  if (fallback) {
    checkOpen((opened: boolean) => {
      if (!opened) {
        fallback(opened);
      }
    });
  }
  document.body.appendChild(ifr);
  setTimeout(() => {
    document.body.removeChild(ifr);
  }, 2000);
}

/**
 * @description 唤醒app
 * @param appUrl {number} 必须传入数字类型
 * */
export const openApp = (appUrl: string) => {
  // setTimeout(() => {
  //   if (getSystemEnv() === 'ios') {
  //     // 修改ios的唤起方式为iframe
  //     // 目的是为了不阻断openinstall的唤起和安装流程
  //     const iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';
  //     iframe.src = appUrl;
  //     document.body.appendChild(iframe);
  //   } else {
  //     const toUrl = appUrl.replace('https', 'linglupin');
  //     window.location.href = toUrl;

  //     // 最后的兜底的逻辑。
  //     setTimeout(() => {
  //       window.location.href = getTraceLink(
  //         `/app?appUrl=${encodeURIComponent(appUrl)}`,
  //       );
  //     }, 1000);
  //   }
  // }, 4000);
  if (getSystemEnv() === 'ios') {
    // // 修改ios的唤起方式为iframe
    // // 目的是为了不阻断openinstall的唤起和安装流程
    // const iframe = document.createElement('iframe');
    // iframe.style.display = 'none';
    // iframe.src = appUrl;
    // document.body.appendChild(iframe);
    window.location.href = appUrl;
  } else {
    const toUrl = appUrl.replace('https', 'linglupin');
    window.location.href = toUrl;

    // 最后的兜底的逻辑。
    setTimeout(() => {
      window.location.href = getTraceLink(
        `/app?appUrl=${encodeURIComponent(appUrl)}`,
      );
    }, 3000);
  }

};

/**
 * @description 判断系统语言环境
 * @param req {any} 请求头
 * */
export const getLocalesEnv = (req: any, params?: any): LANG => {
  const urlLang = params?._lang;
  if (['zh', 'en'].includes(urlLang)) {
    return urlLang;
  }

  const accept = accepts(req);
  const acceptLanguages = accept.languages();
  const firstLanguage = acceptLanguages[0] || 'en';
  return firstLanguage.indexOf('zh') > -1 ? 'zh' : 'en';
};

/**
 * 校验图片
 * */
export const validUploadImage = (file: any) => {
  const isJPEG = file.type === 'image/jpeg';
  const isJPG = file.type === 'image/jpg';
  const isPNG = file.type === 'image/png';

  return !(!isJPEG && !isJPG && !isPNG);
};

/**
 * 校验文件类型
 * */
export const validUploadFile = (file: any) => {
  const isPDF = file.type === 'application/pdf';
  const isDOC = file.type === 'application/doc';
  const isDOCX = file.type === 'application/docx';
  const isMSWORD = file.type === 'application/msword';
  const isDOCX2 =
    file.type ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  return !(!isPDF && !isDOC && !isDOCX && !isMSWORD && !isDOCX2);
};

/**
 * 校验文件大小
 * */
export const validFileSize = (file: any, num: number = 2) => {
  return Number((file.size / 1024 / 1024).toFixed(3)) <= num;
};

export const arrTrans = (num: number, arr: any[]) => {
  const nArr: any[] = [];

  arr.forEach((item, index) => {
    const page = Math.floor(index / num);

    if (!nArr[page]) {
      nArr[page] = [];
    }

    nArr[page].push(item);
  });

  return nArr;
};

export function replaceRouteParamsInString(
  route: any,
  inputString: string,
): string {
  const queryParams = route.query; // 获取路由的查询参数
  const routeParams = route.params; // 获取路由的参数

  // 将路由参数合并到一个字典中，查询参数优先级更高
  const paramDict: Record<string, string> = { ...routeParams, ...queryParams };

  // 使用正则表达式查找字符串中的参数占位符，例如：{name}
  const regex = /{(\w+)}/g;
  let result = inputString;

  // 使用正则表达式的 exec 方法循环查找匹配项
  let match = regex.exec(inputString);
  while (match) {
    // 获取参数名，例如：name
    const paramName = match[1];

    // 检查参数字典中是否存在与参数名相对应的值
    if (paramDict.hasOwnProperty(paramName)) {
      // 替换字符串中的参数占位符为参数字典中的值
      result = result.replace(match[0], paramDict[paramName]);
    }

    // 继续查找下一个匹配项
    match = regex.exec(inputString);
  }

  return result;
}

export const px2rem = (px: number) => {
  return px / 37.5 + 'rem';
};
