import ClientRequest from './client-request';
import ServerRequest from './server-request';

export default class WxAPI {
  private _request;

  constructor(request: ServerRequest | ClientRequest) {
    this._request = request;
  }

  // 上传
  async setFile(params: {}) {
    const res = await this._request.post(`/v1/common/file/uploadImage`, {
      data: params,
    });
    return res.data;
  }
}
