import ClientRequest from './client-request';
import DownloadAPI from './download-api';
import EventsAPI from './events-api';
import LandingAPI from './landing-api';
import WxAPI from './wx-api';
import CommonApi from './common-api';
import PositionAPI from './position-api';
import OrganizationAPI from './organization-api';

class ClientAPI {
  private _request: ClientRequest;

  public landing: LandingAPI;
  public download: DownloadAPI;
  public events: EventsAPI;
  public wx: WxAPI;
  public common: CommonApi;
  public position: PositionAPI
  public organization: OrganizationAPI

  constructor() {
    this._request = new ClientRequest({
      acceptLanguage: 'zh',
    });

    this.landing = new LandingAPI(this._request);
    this.download = new DownloadAPI(this._request);
    this.events = new EventsAPI(this._request);
    this.wx = new WxAPI(this._request);
    this.common = new CommonApi(this._request);
    this.position = new PositionAPI(this._request)
    this.organization = new OrganizationAPI(this._request)
  }

  public set acceptLanguage(lang: LANG) {
    this._request.accessLanguage = lang;
  }

  public get acceptLanguage(): LANG {
    return this._request.accessLanguage;
  }
}

export default new ClientAPI();
