import ServerRequest from './server-request';
import ClientRequest from './client-request';

export default class LandingAPI {
  private _request: ServerRequest | ClientRequest;

  constructor(request: ServerRequest | ClientRequest) {
    this._request = request;
  }

  async getAccount(organizationId: string, accountId: string) {
    const res = await this._request.get(
      `/v1/individual/position/${organizationId}/account/${accountId}`,
    );
    return res.data;
  }

  async getAccountPosition(
    organizationId: string,
    memberId: string,
    pagination: RequestPaginationParams,
  ) {
    const res = await this._request.get(
      `/v1/individual/position/by/organization/${organizationId}/member/${memberId}`,
      { params: pagination },
    );
    return res.data;
  }

  async getOrganizationPosition(
    organizationId: string,
    pagination?: RequestPaginationParams,
  ) {
    const res = await this._request.get(
      `/v1/individual/position/by/organization/${organizationId}`,
      { params: pagination },
    );
    return res.data;
  }

  async getOrganization(organizationUuid8: string) {
    const res = await this._request.get(
      `/v1/individual/organization/${organizationUuid8}`,
    );
    return res.data;
  }

  async getPosition(positionId: string) {
    const res = await this._request.get(
      `/v1/individual/position/${positionId}`,
      {
        headers: {
          'X-IGNORE-LOCALE': 1
        }
      }
    );
    return res.data;
  }
}
