import styles from '@/styles/app.module.scss'
import type { GetServerSideProps, GetServerSidePropsContext } from 'next'
import Layout from '@/layouts/page-base.layout'
import { useEffect } from 'react'
import { getLocalesEnv, getSystemEnv } from '@/utils'
import { WEB3_EVENT_ID, WEB3_EVENT_OPERATE } from '@/consts'

import clientAPI from '@/api/client-api'
import useOpenInstall from '@/hooks/useOpenInstall'
import useChannel from '@/hooks/useChannel'

import imageLogo from '@/static/images/logo.png'
import SmartDeerAppSvg from '@/static/images/app/smartdeer-app.svg'
import HiImage from '@/static/images/app/hi.webp'
import WelcomeSvg from '@/static/images/app/welcome.svg'
import DownloadAppSvg from '@/static/images/app/download-app.svg'

import SmartDeerAppSvgEn from '@/static/images/app/smartdeer-app-en.svg'
import HiImageEn from '@/static/images/app/hi-en.webp'
import WelcomeSvgEn from '@/static/images/app/welcome-en.svg'
import DownloadAppSvgEn from '@/static/images/app/download-app-en.svg'

import useTrack from '@/hooks/useTrack'
import Head from '@/components/headv3'

import dynamic from 'next/dynamic'
import { Toast } from 'antd-mobile'
const WeChat = dynamic(import('@/components/wx'), { ssr: false })

function handleActivityTask(query: any) {
  // 在APP页面，openId都是从url中获取的
  if (query.openId) {
    const eId = query.eventId || WEB3_EVENT_ID
    clientAPI.events.setOrganizationPopular(eId, {
      score: WEB3_EVENT_OPERATE.download.score,
      organizationUuid8: query.orgId,
      operateType: WEB3_EVENT_OPERATE.download.type,
      openId: query.openId,
    })
  }
}

export default function Page(props: PageProps) {
  const { query, seo, share, config } = props
  const openInstall = useOpenInstall()
  const channel = useChannel()
  const track = useTrack()
  const textChannelCode = query._ch || query.channelCode || ''

  // TODO: 这里需要修改为openinstall的唤醒
  function handleAppSilentWakeUp(query: any) {
    // 如果query参数中有appUrl，则表示是APP静默唤醒
    if (query.appUrl) {
      if (!window) return
      const toUrl = query.appUrl.replace('https', 'linglupin')
      window.location.href = toUrl
    }
  }

  function handleDownloadAppClick() {
    const env = getSystemEnv()
    track.click('mkt_app_download_click', { env, channel: channel?.channel, shareCode: channel?.shareCode })
    // openInstall?.wakeupOrInstall({ channelCode: channel?.channel, shareCode: channel?.shareCode })

    var userAgent = navigator.userAgent;

    var isiOS = /iPad|iPhone|iPod/.test(userAgent);
    var isWeChatBrowser = userAgent?.toLocaleLowerCase().indexOf('micromessenger') !== -1;
    var url;
    if (isiOS) {
      // if (isWeChatBrowser) {
        // url = 'https://apps.apple.com/app/id1669194296'
        // url = 'apps://itunes.apple.com/app/id1669194296'
        // url = 'universe.smartdeer.com'
      // } else {
      url = 'itms-apps://itunes.apple.com/app/id1669194296';
      // }
    } else {
      url = 'https://smartdeer.com/app/download/latest';
    }
    window.location.href = url;
  }

  useEffect(() => {
    // 处理大专场活动的访问任务
    handleActivityTask(query)
    // 处理APP静默唤醒
    handleAppSilentWakeUp(query)
  }, [])

  function GetContent({ channel }: any) {
    return <>
      <div className={styles['']}>
        <object className={styles['smartdeer-app-image']} type="image/svg+xml" data={config.smartDeerAppImage} />
      </div>
      <div className={styles['']}>
        <object className={styles['hi-image']} type="image/webp" data={config.hiImage} />
        <object className={styles['welcome-image']} type="image/svg+xml" data={config.welcomeImage} />
      </div>
      <div onClick={handleDownloadAppClick}>
        <img className={styles['download-app-image']} src={config.downloadAppImage} alt='下载SmartDeer APP' />
      </div>
    </>
  }

  const page = (
    <Layout pagename='universe_app_download'>
      <Head seo={seo} share={share}></Head>
      <WeChat share={share} />
      <div className={styles['app']}>
        <div className={styles['app-body']}>
          <GetContent channel={textChannelCode}></GetContent>
        </div>
      </div>
    </Layout>
  )
  return page
}

const CONFIG_ZH = {
  seo: {
    title: 'SmartDeer｜全球好工作，直接谈！',
    description: '在SmartDeer找工作，你将获得更突破的职业发展、更灵活的远程办公、更丰厚的长期回报！',
    keywords: ''
  },
  share: {
    title: '下载SmartDeerAPP｜全球好工作，直接谈！',
    description: '在SmartDeer找工作，你将获得更突破的职业发展、更灵活的远程办公、更丰厚的长期回报！',
    image: imageLogo.src,
    thumbnail: imageLogo.src,
  },
  config: {
    smartDeerAppImage: SmartDeerAppSvg.src,
    hiImage: HiImage.src,
    welcomeImage: WelcomeSvg.src,
    downloadAppImage: DownloadAppSvg.src,
  }
}

const CONFIG_EN = {
  seo: {
    title: 'SmartDeer APP',
    description: 'Access global Web3 jobs in one place.',
    keywords: ''
  },
  share: {
    title: 'SmartDeer APP - Chat Directly for Global Web3 Jobs',
    description: 'Access global Web3 jobs in one place.',
    image: imageLogo.src,
    thumbnail: imageLogo.src,
  },
  config: {
    smartDeerAppImage: SmartDeerAppSvgEn.src,
    hiImage: HiImageEn.src,
    welcomeImage: WelcomeSvgEn.src,
    downloadAppImage: DownloadAppSvgEn.src,
  }
}

interface PageProps {
  query: any,
  seo: ICB.SEOConfig,
  share: ICB.ShareConfig,
  config: any,
}

// 钩子函数，在请求时被自动调用
export const getServerSideProps: GetServerSideProps<PageProps> = async (props) => {

  const { query, res, req } = props
  const Language = getLocalesEnv(req, query)

  const config = Language === 'zh' ? CONFIG_ZH : CONFIG_EN
  return {
    props: { 
      query,
      seo: config.seo,
      share: config.share,
      config: config.config
    },
  }
}
