import ClientRequest from './client-request';
import ServerRequest from './server-request';

export default class DownloadAPI {
  private _request;

  constructor(request: ServerRequest | ClientRequest) {
    this._request = request;
  }

  async getAppConfig() {
   const res = await this._request.get(`/v1/app/config`)
   return res.data
  }

  async getApkDownloadConfig() {
    const params = { key: 'MKTH5.appDownloadConfig',language: 'en',version: '1' }
    const { data } = await this._request.post(
      '/v1/common/dict/conf/formatted',
      { 'data': params },
    )
    return JSON.parse(data.data.json)
  }
}