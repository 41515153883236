import ClientRequest from './client-request';
import ServerRequest from './server-request';

export default class OrganizationAPI {
  private _request;

  constructor(request: ServerRequest | ClientRequest) {
    this._request = request;
  }

  async getOrganizationByIds(organizationIds:number[]):Promise<{data: ICB.Organization[]}> {
    const res = await this._request.post('/v1/event/data', {
      data: { type:2, params:organizationIds }
    })
    return res.data
  }
}
